module.exports = [{
      plugin: require('../../gatsby-theme-tumbledown/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo - Tumbledown - HVAC","short_name":"Demo - Tumbledown - HVAC","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3129c9f542907bf3ba229e35304dacd5"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://tumbledown-hvac.fieldroutesthemes.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../../gatsby-theme-tumbledown/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"4cffd71c-3e63-0064-3cda-6bac21ad1f94","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImVlMjNjNWRlNGFmYjQyMDc5NjQ5ZGYzY2U1MmRkZjczIiwNCiAgImlhdCI6ICIxNjcxMDQwMTEwIiwNCiAgImV4cCI6ICIyMDE2NjQwMTEwIiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogIjRjZmZkNzFjM2U2MzAwNjQzY2RhNmJhYzIxYWQxZjk0IiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.YzMqgYiK46pjXOOX4XALJ1wG5INmJFLByMPmZ0yT3oY","usePreviewUrl":"false","siteUrl":"https://tumbledown-hvac.fieldroutesthemes.com","title":"Demo - Tumbledown - HVAC","description":"Demo - Tumbledown - HVAC","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
